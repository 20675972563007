import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useAccountContext from '@/hooks/context/useAccountContext';
import { loadIntercomScript } from '@/utility/intercom';

type IntercomContextModel = {
  inUseBy: string[];
  isOpen: boolean;
  dispatchActivateIntercom: (value: string) => void;
  dispatchDeactivateIntercom: (value: string) => void;
  dispatchOpenIntercom: () => void;
};

export const IntercomContext = createContext<IntercomContextModel | undefined>(undefined);

const IntercomProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const { account } = useAccountContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inUseBy, setInUseBy] = useState<string[]>([]);
  const [isActive, setIsActive] = useState<boolean>(false);

  const intercomSettings = useMemo(
    () => ({
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      alignment: 'right',
      horizontal_padding: 20,
      vertical_padding: 20,
      custom_launcher_selector: '#launcher',
    }),
    []
  );

  const intercomUserSettings = useMemo(
    () => ({
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      name: account?.contact.fullName,
      email: account?.contact.email,
      user_id: account?.id,
      user_hash: account?.id,
    }),
    [account?.contact.email, account?.contact.fullName, account?.id]
  );

  const bootIntercom = useCallback(() => {
    window.intercomSettings = intercomSettings;
    window.Intercom('boot', intercomUserSettings);
    window.Intercom('onShow', () => setIsOpen(true));
    window.Intercom('onHide', () => setIsOpen(false));
    setIsActive(true);
  }, [intercomSettings, intercomUserSettings]);

  const dispatchActivateIntercom = useCallback((value: string) => {
    setInUseBy((currentValue) => (!currentValue.includes(value) ? [...currentValue, value] : currentValue));
  }, []);

  const dispatchDeactivateIntercom = useCallback((value: string) => {
    setInUseBy((currentValue) =>
      currentValue.includes(value) ? currentValue.filter((name) => name !== value) : currentValue
    );
  }, []);

  const dispatchOpenIntercom = useCallback(() => {
    setIsOpen(true);
    window.Intercom('show');
  }, []);

  const contextValue: IntercomContextModel = {
    inUseBy,
    isOpen,
    dispatchActivateIntercom,
    dispatchDeactivateIntercom,
    dispatchOpenIntercom,
  };

  useEffect(() => {
    if (inUseBy.length > 0 && !isActive) {
      bootIntercom();
    }

    if (inUseBy.length === 0 && isActive) {
      setIsActive(false);
      window.Intercom('shutdown');
    }
  }, [bootIntercom, inUseBy, isActive]);

  useEffect(() => {
    if (!location.pathname.includes('home')) {
      dispatchDeactivateIntercom('home');
    }

    if (!location.pathname.includes('settings')) {
      dispatchDeactivateIntercom('settings');
    }
  }, [dispatchDeactivateIntercom, location]);

  useEffect(() => {
    loadIntercomScript();
    return () => {
      window.Intercom('shutdown');
    };
  }, []);

  return <IntercomContext.Provider value={contextValue}>{children}</IntercomContext.Provider>;
};

export default IntercomProvider;
