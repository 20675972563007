import React, { createContext, ReactNode, useCallback, useState } from 'react';

type PayloadType = 'error' | 'success' | 'info';
type SnackbarType = 'OPEN_SNACKBAR' | 'CLOSE_SNACKBAR';

export type SnackbarPayloadModel = {
  message: string;
  type: PayloadType;
};

export type SnackbarModel = {
  type: SnackbarType;
  payload?: SnackbarPayloadModel;
};

type SnackbarContextModel = {
  open: boolean;
  snackbar: SnackbarPayloadModel | undefined;
  dispatchSnackbar: (value: SnackbarModel) => void;
};

export const SnackbarContext = createContext<SnackbarContextModel | undefined>(undefined);

const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarPayloadModel>();

  const dispatchSnackbar = useCallback(async (value: SnackbarModel) => {
    switch (value.type) {
      case 'OPEN_SNACKBAR':
        setSnackbar(value.payload);
        setOpen(true);
        break;
      case 'CLOSE_SNACKBAR':
        setSnackbar(undefined);
        setOpen(false);
        break;
    }
  }, []);

  const contextValue: SnackbarContextModel = {
    open,
    snackbar,
    dispatchSnackbar,
  };

  return <SnackbarContext.Provider value={contextValue}>{children}</SnackbarContext.Provider>;
};

export default SnackbarProvider;
